import React from 'react'
import { Card, Stack, ListGroup } from 'react-bootstrap'
import captian from '../images/captian.jpg'
import boatcert from '../images/boatcert.jpg'
import '../styles/boating.css'

function Boating() {
  return (
    <div className='boating'>
      <Stack gap={2} className="col-md-5 mx-auto mt-10">
        <Card style={{ width: '30rem' }}>
          <Card.Img variant="top" src={captian} />
          <Card.Body>
            <Card.Title style={{ fontSize: "30px" }}>Motorboating Instruction</Card.Title>
            <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: "20px" }}>US Boat Safety Certified</Card.Subtitle>
            <Card.Text>
              <br />
              <Card.Title style={{ fontSize: "25px" }}>We provide instruction on:</Card.Title>
              <ListGroup variant="flush" style={{ fontSize: "20px" }}>
                <ListGroup.Item>Motorboat operation.</ListGroup.Item>
                <ListGroup.Item>GPS and navigation use.</ListGroup.Item>
                <ListGroup.Item>Chartting courses on waters.</ListGroup.Item>
                <ListGroup.Item>Driving and docking techniques.</ListGroup.Item>
                <ListGroup.Item>Dropping and retrieving anchor.</ListGroup.Item>
                <ListGroup.Item>Understanding boating safety.</ListGroup.Item>
              </ListGroup>
            </Card.Text>
          </Card.Body>
          <Card.Img variant="top" heigth="50px" width="50px" src={boatcert} />
        </Card>
      </Stack>
    </div>
  )
}

export default Boating

import React from 'react'
import { Card, Stack, ListGroup } from 'react-bootstrap'
import notary from '../images/notary.jpg'

function Notary() {
  return (
    <div className='minister'>
      <Stack gap={2} className="col-md-5 mx-auto mt-10">
        <Card style={{ width: '30rem' }}>
          <Card.Img variant="top" src={notary} />
          <Card.Body>
            <Card.Title style={{ fontSize: "30px" }}>Notary Public</Card.Title>
            <Card.Text style={{ fontSize: "20px" }}>
              An official of integrity appointed and Publicly Commissioned
              by the Secretary of State to serve the public as an impartial witness.
              <br /><br />
                <Card.Title style={{ fontSize: "25px" }}>Help prevent fraud by:</Card.Title>
              <ListGroup variant="flush" style={{ fontSize: "20px" }}>
                <ListGroup.Item>Witnessing the signing of documents and verifying their authenticity.</ListGroup.Item>
                <ListGroup.Item>Administering oaths and affirmations.</ListGroup.Item>
                <ListGroup.Item>Taking acknowledgements of deeds and other conveyances.</ListGroup.Item>
              </ListGroup>
            </Card.Text>
          </Card.Body>
        </Card>
      </Stack>
    </div>
  )
}

export default Notary

import React from 'react'
import { Card, Stack } from 'react-bootstrap'
import '../styles/about.css'
import jpa from '../images/jpa-logo.jpg'

function About() {
  return (
    <div className='about-section'>
      <Stack gap={2} className="col-md-5 mx-auto mt-10">
        <Card>
          <Card.Header style={{ fontSize: "30px" }}>Welcome to JPA & Associates</Card.Header>
          <Card.Img variant="top" src={jpa} />
          <Card.Title style={{ fontSize: "25px", marginLeft: "15px" }}>Committed to  Excellence</Card.Title>
          <Card.Body>
            <Card.Text style={{ fontSize: "20px" }}>
              After 42 years at IBM as a highly successful Sales & Services Executive, I retired and established
              JPA & Associates in 2021.
              <br />
              Our main goal is to always achieve a high level of
              customer satisfaction with the services that we provide.
              When it comes to our services, you can count on us to take care of your every need.
              We’re thrilled you’ve decided to visit us -
              please browse our site to discover what we’re all about.
            </Card.Text>
          </Card.Body>
          <Card.Title style={{ fontSize: "25px", marginLeft: "15px" }}>Contact</Card.Title>
          <Card.Body>
            <Card.Text style={{ fontSize: "20px" }}>
              John P Alloggiamento, Founder & CEO
              <br />
              Phone: <a href="tel:516-425-2133">516-425-2133</a>
              <br />
              Email: <a a href="mailto: jpa@jpaandassociates.com">jpa@jpaandassociates.com</a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Stack>
    </div>
  )
}

export default About

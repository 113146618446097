import React from 'react'
import { Card, Stack, ListGroup } from 'react-bootstrap'
import problemfixer from '../images/problemfixer.jpg'
import '../styles/problemfixer.css'

function ProblemFixer() {
  return (
    <div className='problemfixer'>
      <Stack gap={2} className="col-md-5 mx-auto mt-10">
        <Card style={{ width: '30rem' }}>
          <Card.Img variant="top" src={problemfixer} />
          <Card.Body>
            <Card.Title style={{ fontSize: "30px" }}>Problem Fixer</Card.Title>
            <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: "20px", fontStyle: "italic" }}>"Service with a Smile”</Card.Subtitle>
            <Card.Text >
              <br />
              <Card.Title style={{ fontSize: "25px" }}>We offer the following:</Card.Title>
              <ListGroup variant="flush" style={{ fontSize: "20px" }}>
                <ListGroup.Item>Light handyman services, plumbing, electrical and household tasks.</ListGroup.Item>
                <ListGroup.Item>Feral cat trapping - trap, neuter & release.</ListGroup.Item>
                <ListGroup.Item>Helping others solve personal problems and situations.</ListGroup.Item>
                <ListGroup.Item>Private Investigating.</ListGroup.Item>
              </ListGroup>
            </Card.Text>
          </Card.Body>
        </Card>
      </Stack>
    </div>
  )
}

export default ProblemFixer

import React from 'react'
import jpaNav from '../images/jpa-nav.png'
import flag from '../images/flag.png'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const NavBar = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src={jpaNav} alt='jpa-logo' height='150px' width='200px' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" style={{ fontSize: "25px" }}>Home</Nav.Link>
            <NavDropdown style={{ fontSize: "25px" }} title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="/services/boating" style={{ fontSize: "20px" }}>Boating</NavDropdown.Item>
              <NavDropdown.Item href="/services/matrimonial" style={{ fontSize: "20px" }}>Matrimonial</NavDropdown.Item>
              <NavDropdown.Item href="/services/notary" style={{ fontSize: "20px" }}>Notary</NavDropdown.Item>
              <NavDropdown.Item href="/services/problem-fixer" style={{ fontSize: "20px" }}>Problem Fixer</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        {/* <Nav>
          <Navbar.Brand href="/"><img src={flag} alt='jpa-logo' height='150px' width='200px' /></Navbar.Brand>
        </Nav> */}
      </Container>
    </Navbar>
  )
}

export default NavBar

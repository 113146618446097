import React from 'react'
import { Card, Stack } from 'react-bootstrap'
import amm from '../images/amm.jpg'
import '../styles/minister.css'

function Minister() {
  return (
    <div className='minister'>
      <Stack gap={2} className="col-md-5 mx-auto mt-10">
        <Card style={{ width: '30rem' }}>
          <Card.Img variant="top" src={amm} />
          <Card.Body>
            <Card.Title style={{ fontSize: "30px" }}>Ordained Minister</Card.Title>
            <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: "20px" }}>AMM Certified</Card.Subtitle>
            <Card.Text style={{ fontSize: "20px", marginTop: "20px" }}>
              As an ordained Minister, I officiate wedding ceremonies and wedding vow renewals for friends,
              family and community.
              <br />
              American Marriage Ministries is a 501C3 non-profit constitutional church, incorporated on July 4th, 2009.
              Our mission is securing the rights of all people to celebrate marriages that reflect
              their values and beliefs.
            </Card.Text>
          </Card.Body>
        </Card>
      </Stack>
    </div >
  )
}

export default Minister


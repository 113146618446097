import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavBar from './components/NavBar'
import Boating from './components/Boating'
import Minister from './components/Minister'
import Notary from './components/Notary'
import ProblemFixer from './components/ProblemFixer'
import About from './components/About'

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path='/' exact element={<About />} />
          <Route path='/services/boating' element={<Boating />} />
          <Route path='/services/matrimonial' element={<Minister />} />
          <Route path='/services/notary' element={<Notary />} />
          <Route path='/services/problem-fixer' element={<ProblemFixer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
